(function ( $ ) {
     $.fn.projectMain = function (options) {
        var scrollTop = 0;
        var objBody   = $(this);

        function _init() {
            _initMenu();
            _initCloseContact();
            _initFormFocuses();
            _initPhotogalery();
            _initShowMore();
            _initModals();
            _initUploader();
            _initSelect();
            _initUserLink();
            _initShare();
            _initCke();
        }

        function _initCke(){
            $( 'textarea[name="popis_inzeratu"]' ).ckeditor();

            $('input').iCheck({
              checkboxClass: 'icheckbox_minimal-red',
              radioClass: 'iradio_minimal-red',
              increaseArea: '20%' // optional
            });

            $('input[name="dohodou"]').on('ifChecked', function(event){
                $(this).closest('.price').addClass('disabled').find('input[type="text"]').attr('disabled','disabled');
            }).on('ifUnchecked', function(event){
                $(this).closest('.price').removeClass('disabled').find('input[type="text"]').removeAttr('disabled');
            });
        }

        function _initShare(){
            $('#product-detail .contacts-line .share').click(function(){
                $('#share').addClass('active');
            });
            $('#share a.close').click(function(){
                $('#share').removeClass('active');
            });

            $('#share .container .link button').click(function(){
                var $tempElement = $("<input>");
                $("body").append($tempElement);
                $tempElement.val($('#share .container .link span').text().trim()).select();
                document.execCommand("Copy");
                $tempElement.remove();

                $(this).html('Kopirováno').addClass('copied');
            });
        }

        function _initUserLink(){
            $('#my-accont .choice a').click(function(){
                $('#my-accont .list-inzs').addClass('hidden');
                $('#my-accont #'+$(this).attr('data-target')).removeClass('hidden');
            });
        }

        function _initSelect(){
            $('select').selectric();
        }

        function _uploaderSetMain(obj){
            obj.click(function(){
                $('input[name="img_head"]').attr('value',obj.attr('data-id'));
                $( ".uploader .imgs .imgr" ).removeClass('active');
                obj.closest('.imgr').addClass('active');
            });
        }

        function _uploaderDeleteImg(obj){
            obj.click(function(){
                $('.uploader .imgr[data-id="'+obj.attr('data-id')+'"]').remove();
                $('#images-list #file_'+obj.attr('data-id')).remove();
                if(obj.attr('data-id') == $('input[name="img_head"]').attr('value')){
                    $( ".uploader .imgs .imgr:eq( 0 )" ).addClass('active');
                }
            });
        }

        function _initUploadLinks(){
            $('.uploader .imgs a').each(function (index) {
                switch($(this).attr('data-type')) {
                  case 'set-main':
                     _uploaderSetMain($(this));
                  break;
                  default:
                     _uploaderDeleteImg($(this));
                  break;
                }
            });
        }

        function _initUploader(){
            if($('body').has('#fileuploader').length > 0){
                $("#fileuploader").uploadFile({
                    url: ajaxurl,
                    method: 'POST',
                    formData: {'action': 'upload_fotos' },
                    multiple:true,
                    dragDrop:false,
                    fileName:"myfile",
                    onLoad :function(obj){
                        _initUploadLinks();
                    	console.log('loaded');
                    },
                    onSubmit:function(files)
                    {
                    	$('.uploader .loaderUpload').addClass('active');
                    },
                    onSuccess:function(files,data,xhr,pd){
                        $('#images-list').append('<input type="hidden" id="file_'+data.html+'" name="file[]" value="'+data.html+'" />');
                    },
                    afterUploadAll:function(obj){
                        ids = '';
                        $( "#images-list input[type='hidden']" ).each(function( index ) {
                            ids += '|'+$(this).val();
                        });
                        $.ajax({
                            url : ajaxurl,
                            data : {'action': 'show_product_photos', 'ids': ids },
                            type : 'POST',
                            success : function( data ){
                                if(data.html != null){
                                    $('.uploader .imgs').html(data.html);
                                    val = $('input[name="img_head"]').val();
                                    if(val == ""){
                                        $('input[name="img_head"]').attr('value',$( ".uploader .imgs .imgr:eq( 0 )" ).attr('data-id'));
                                        $( ".uploader .imgs .imgr:eq( 0 )" ).addClass('active');
                                    }
                                    /*
                                    $('#product-detail .content').html(data.html);
                                    $('#product-detail').addClass('loaded');
                                    _initPhotogalery();
                                    _initFeatured();
                                    */
                                    _initUploadLinks();
                                }
                            }
                        });
                        $('.uploader .loaderUpload').removeClass('active');
                    },
                });
                $('#add-form .btns button').click(function(){
                    $('#add-new-form').submit();
                });

                $('.form .uploader .btn').click(function(){
                    $( '#fileuploader input[type="file"]' ).trigger( "click" );
                });
            }
        }

        function _initModals(){
            $('.modalBtn').click(function(){
                $('.modal').removeClass('active loading loaded');
                $('#'+$(this).attr('data-target')).addClass('active loading loaded');
            });
            $('.modal .close').click(function(){
                $(this).closest('.modal').removeClass('active loading loaded');
            })
            $('#add-inz.modal .choice a').click(function(){
                $('#add-inz.modal .choice a').removeClass('active');
                $(this).addClass('active');
                $('#add-inz.modal .form-log').addClass('hidden');
                $('#add-inz.modal .form-log#'+$(this).attr('data-target')).removeClass('hidden');
            })
        }

        function _initMenu(){
            $("#head .mobileNav").click(function(){
                if($('#head ul').hasClass('active')){
                    $("#head .mobileNav i").removeClass('icon-close').addClass('icon-menu');

                    $('#head ul').removeClass('active');
                    $('body').removeClass('locked');
                }else{
                    $("#head .mobileNav i").addClass('icon-close').removeClass('icon-menu');

                    $('#head ul').addClass('active');
                    $('body').addClass('locked');
                }
            });

            $("#contactBtn").click(function(){
                $('#contacts').addClass('active loading loaded');
            })
        }

        function _initCloseContact(){
            $('#contacts a.close').click(function(){
                $(objBody).removeClass('locked');
                $('html, body').animate({
                      scrollTop: scrollTop
                }, 0);
                $('#contacts').removeClass('active loading loaded');
            });
        }

        function _initShowMore(){
            $('#product-detail .text .more').click(function(){
                $(this).addClass('hide');
               $('#product-detail .text ul').addClass('active');
            });
        }

        function _initPhotogalery(){
            var owlPhotos = $('.owl-photogalery');
            var owlFeatured = $('.owl-featured');
            owlPhotos.owlCarousel({
                loop: false,
                margin: 25,
                nav: true,
                dots: true,
                navText: ['<i class="icon icon-arrow-left"><i>','<i class="icon icon-arrow-right"><i>'],
                responsive : {
                    0 : {
                        items: 1
                    },
                    768 : {
                        items: 2
                    },
                    880 : {
                        items: 3
                    },
                    1100 : {
                        items: 4
                    }
                },
                autoplay:false
            });

            owlFeatured.owlCarousel({
                loop: false,
                margin: 25,
                nav: true,
                dots: true,
                navText: ['<i class="icon icon-arrow-left"><i>','<i class="icon icon-arrow-right"><i>'],
                responsive : {
                    0 : {
                        items: 1
                    },
                    880 : {
                        items: 2
                    },
                    1100 : {
                        items: 3
                    }
                },
                autoplay:false
            });

            $('.head .img').magnificPopup({
                type: 'image',
                delegate: 'a',
                gallery: {
                    enabled: true
                }
            });
        }

        function _initFormFocuses(){
            forms = $(objBody).find('.gform_wrapper');
            //forms = $(forms).find('.form');
            $(forms).find('input[type="text"],input[type="password"],textarea').each(function( index ) {
                $(this).on("focus", function() {
                    $(this).closest('li').addClass('focus filled');
                }).on("blur", function() {
                    $(this).closest('li').removeClass('focus');
                    val = $(this).val();
                    if(val == ''){
                        $(this).closest('li').removeClass('filled');
                    }
                });
            });
        }

        _init();
       return {
         reload:_init,
       };
    };
}( jQuery ));

$("body").projectMain();
